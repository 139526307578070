export default function (anchor) {
	if (!anchor) return null;

	if (!anchor.anchorButton) {
		return {
			href: anchor.internalElement,
			text: anchor.anchorText,
		};
	}

	return {
		href: anchor.internalElement,
		text: anchor.anchorText,
		button: anchor.anchorButton,
	};
}
